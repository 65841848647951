.p-dialog{
    background-color: white;
}
.NeoModal {
    background-color: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;


    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    .body {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 50%;

        .header {
            display: grid;
            grid-template-columns: 95% 5%;
            grid-template-rows: auto;
            border-top-left-radius: $container-border-radius;
            border-top-right-radius: $container-border-radius;
            padding: 10px;
            background-color: $secondary-gray;

            .title {
                h2 {
                    font-weight: 600;
                    color: $secondary-color-1;
                }
            }

            .btn-cerrar {
                text-align: center;
                margin-top: auto;

                svg {
                    color: $red;
                    font-size: 1.60em;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .content {
            border-bottom-left-radius: $container-border-radius;
            border-bottom-right-radius: $container-border-radius;
            padding: 10px;
            background-color: white;
        }
    }
}
