@mixin selectButtonBorderRadius() {
	border-radius: 18px !important;
	-webkit-border-radius: 18px !important;
	-moz-border-radius: 18px !important;
	-ms-border-radius: 18px !important;
	-o-border-radius: 18px !important;
}
button{
	font-family: inherit;
}


.sd {

	.p-button,
	.p-dialog .p-button {
		color: #ffffff;
		font-size: 14px !important;
		padding: 8px 20px !important;
		line-height: 1.5;
		background: $secondary-color-1;
		border: 1px solid $secondary-color-1;

		span:not(.p-button-icon) {
			font-family: 'Nunito', sans-serif;

		}

		&:enabled:hover {
			background: lighten($secondary-color-1, 10);
			color: #ffffff;
			border-color: lighten($secondary-color-1, 10);
		}

		&:enabled:focus {
			box-shadow: 0 0 0 0.2rem #beccd2;
		}

		&:enabled:active {
			background: $secondary-color-2;
			color: #ffffff;
			border-color: $secondary-color-2;
		}

		&.p-button-secondary {
			color: #ffffff;
			background: $main-color-2;
			border: 1px solid $main-color-2;

			&:enabled:hover {
				background: darken($main-color-2, 5);
				color: #ffffff;
				border-color: darken($main-color-2, 5);
			}

			&:enabled:focus {
				box-shadow: 0 0 0 0.2rem #beccd2;
			}

			&:enabled:active {
				background: darken($main-color-2, 3);
				color: #ffffff;
				border-color: darken($main-color-2, 3);
			}
		}

		&.p-button-outlined {

			border: 1px solid;
		}

		&.p-button-outlined,
		&.p-button-text {
			background-color: transparent;
			color: $secondary-color-1;

			//border: 1px solid;
			&:enabled:hover {
				background: rgba(96, 125, 139, 0.04);
				color: $secondary-color-1;
				//border: 1px solid;
			}

			&:enabled:active {
				background: rgba(96, 125, 139, 0.16);
				color: $secondary-color-1;
				//border: 1px solid;
			}

			&.p-highlight.no-toggle {
				background-color: transparent;
				color: $secondary-color-1;
				border: 1px solid $secondary-color-1;
			}

			&.button-inverted {

				color: white;
				border-color: white;

				//border: 1px solid;
				&:enabled:hover {
					background: rgba(96, 125, 139, 0.04);
					color: white;
					//border: 1px solid;
				}

				&:enabled:active {
					background: rgba(158, 166, 170, 0.16);
					color: white;
					//border: 1px solid;
				}
			}

		}

		&.p-button-link {
			background-color: transparent;
			color: $blue-text;
			background: transparent;
			border: transparent;

			//border: 1px solid;
			&:enabled:hover {
				text-decoration: underline;

				//border: 1px solid;
			}

			&:enabled:active {
				text-decoration: underline;
				//border: 1px solid;
			}
		}

		&.warning {
			background-color: $main-color-3;

			&:enabled:hover {
				background: darken($main-color-3, 5);
			}
		}

		&.p-fileupload-choose {
			border-radius: 20px;
		}

		&.delete-button {
			background-color: transparent;
			padding: 0;
			margin: 0;
			border: 1px solid black;
			color: black;
			position: absolute;
			right: 0;
		}

		&.split-button-menubutton{
			margin-left: 0 !important;
		}
	}

	.dato-secundario + .p-button{
		margin-left: 1.5rem;
	}

}






.p-selectbutton {
	@include selectButtonBorderRadius;
	border: 1px solid #ced4da;
	padding: 0;

	.p-button {
		@include selectButtonBorderRadius;
		border: none !important;
		background: none !important;
		margin-bottom: 0;
		flex: 1;

		.p-button-label {
			color: #495057;
		}

		&.p-highlight {
			$background-color: $secondary-color-1;
			background: $background-color !important;

			&:hover {
				background-color: $background-color !important;
			}

			.p-button-label {
				color: #fff;
			}
		}

		&:focus {
			box-shadow: none;
		}

		&:first-child {
			border-top-left-radius: 20px;
			border-top-right-radius: 0px;
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 0px;
		}

		&:last-child {
			border-top-left-radius: 0px;
			border-top-right-radius: 20px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 20px;
		}
	}
}

.sd .p-selectbutton {
	.p-button {
		background: #ffffff;
		border: 1px solid #ced4da;
		color: #495057;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

		.p-button-icon-left {
			color: #6c757d;
		}

		.p-button-icon-right {
			color: #6c757d;
		}

		&:not(.p-disabled) {
			&:not(.p-highlight) {
				&:hover {
					background: #e9ecef;
					border-color: #ced4da;
					color: #495057;

					.p-button-icon-left {
						color: #6c757d;
					}

					.p-button-icon-right {
						color: #6c757d;
					}
				}
			}
		}
	}

	.p-button.p-highlight {
		background: $secondary-color-2;
		border-color: $secondary-color-2;
		color: #ffffff;

		.p-button-icon-left {
			color: #ffffff;
		}

		.p-button-icon-right {
			color: #ffffff;
		}

		&:hover {
			background: $secondary-color-1;
			border-color: $secondary-color-1;
			color: #ffffff;

			.p-button-icon-left {
				color: #ffffff;
			}

			.p-button-icon-right {
				color: #ffffff;
			}
		}
	}

	&.p-invalid {
		>.p-button {
			border-color: #f44336;
		}
	}

}

.p-splitbutton{
	border-top-right-radius: 0;
    border-bottom-right-radius: 0;
	.p-button{
		border-radius: 2rem;
		
		&.p-splitbutton-menubutton {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

} 

.p-col.seccion-botones {
	padding: 0.5rem 0;

	.p-button {
		width: 100%;

		margin-right: 0.5rem;
		margin-bottom: 0.4rem;

		&:last-child {
			margin-right: 0;
		}

		&.p-button-primary.database-button-validated {
			background: transparent;
			height: 3rem;
			width: auto;
			color: #194893;
			;
		}


	}

	.p-field.p-col {
		margin-top: 0 !important;
		//margin-bottom: 0 !important;
		padding: 0;
		padding-right: 0.5rem;
		margin-bottom: 0.4rem !important;

		.p-inputwrapper {
			padding: 1px 0;
		}
	}

	&.right {
		justify-content: flex-end;

		.p-button {
			width: auto;
		}

		margin-right: 0;
		margin-left: 0.5rem;

		&:last-child {
			margin-right: 0;
			margin-left: 0.5rem;
		}
	}

	&.left {
		justify-content: flex-start;

		.p-button {
			width: auto;
			min-width: 3rem;
			height: 3rem;
			padding: 0 !important;
			justify-content: center;
			align-items: center;

			.p-button-icon {
				margin-right: 0;
			}
		}

		margin-left: 0;
		margin-right: 0.5rem;

		&:last-child {
			margin-left: 0;
			margin-right: 0.5rem;
		}
	}

	&.center {
		justify-content: center;

		.p-button {
			width: auto;
		}

		margin-left: 0.25rem;
		margin-right: 0.25rem;

		&:last-child {
			margin-left: 0.25rem;
			margin-right: 0.5rem;
		}
	}
}

.sd {
	.p-button {
		&.p-button-link.recurrent-campaign-link {
			height: 14px;
		}

		&.close-button {
			background-color: transparent;
			padding: 0;
			margin: 0;
			border: 1px solid black;
			color: black;
			position: absolute;
			right: 0;
		}
	}

	.p-datatable {
		.p-button-link {
			padding: 0;
		}
	}

	.button-img {
		flex-flow: column;
		border-radius: 1em;
		margin: 0.2em;
		padding: 2em;

		img {
			height: 100px;
		}

		.p-button-label {
			display: none;
		}
	}

	.p-button.button-circle {
		padding: 8px !important;
		width: 38px !important;
	}

	.squared-button {
		margin-left: 1rem;
		background-color: #2196F3 !important;
		border-style: none !important;
		border-radius: .5rem !important;
		padding: 4px 5px !important;

		span {
			font-size: 13px;
			font-weight: 700;
		}
	}

	.recurrent-campaign-link {
		padding: 0 !important;
	}

	.no-underline {
		span {

			text-decoration: none !important;

			&:enabled:hover {
				text-decoration: none !important;
			}
		}
	}


	.database-button-filled {
		min-width: 3rem !important;
		height: 3rem;
		border: none !important;
	}

	.database-button-outlined {
		width: 3rem !important;
		height: 3rem;


	}

	.database-button-validated {
		height: 3rem;
		width: auto;
	}

}