.titulo-grafica {
    text-align: center;
    width: 100%;
    margin-bottom: 0;
    margin-top: 1em;
    font-weight: 400;
}

.grafica-contenedor {
    width: 100%;
    min-height: 100px;
}

.dato-ingrafica {

    font-size: 0.9em;
    font-weight: bold;
}

.dato-ingrafica-porcentaje {
    fill: gray;
    font-size: 0.7em;
}


.leyenda-grafica-unidad {
    background-color: white;
    border-left: 8px solid red;
    margin-bottom: 0.7em;

    .leyenda-dato {
        display: block;
        margin-left: 0.5em;
        font-weight: bold;
        @include rem(font-size, 18);
        line-height: 1;
    }

    .leyenda-etiqueta {
        display: block;
        margin-left: 0.5em;
        @include rem(font-size, 12);
        line-height: 1;
    }
}

.leyenda-gráfica-barras,
.leyenda-gráfica-pastel {
    .title {
        margin-bottom: 10px;
    }

    .leyenda-grafica-unidad {
        border-left: 0px;
        margin-bottom: 0.7em;

        .leyenda-dato {

            @include rem(font-size, 16);

        }

        .leyenda-etiqueta {

            @include rem(font-size, 12);

        }

        .codigo-color {
            vertical-align: middle;
            width: 8px;
            height: 26px;
            display: inline-block;
        }

        .leyenda-container {
            vertical-align: middle;
            display: inline-block;
        }
    }

}







.recharts-rectangle.recharts-tooltip-cursor {
    fill: rgb(240, 240, 240) !important;
}

.recharts-default-tooltip {
    padding: 10px 18px 18px 18px;
}

.custom-tooltip {
    background-color: white;
    border-radius: 5px;
    padding: 10px 10px 1px 10px;
    display: block;
    @include shadow(3px 3px 10px rgba(0, 0, 0, 0.18));

    p {
        margin: 0;
    }
}

svg.recharts-surface {
    overflow: visible;
}

.recharts-legend-item-text {
    text-transform: capitalize;
}
