.sd~.p-tooltip {
    &.large {
        max-width: 25rem;
    }

    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.tooltip-icon {
    cursor: pointer;
}