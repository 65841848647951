.ipv-data{
    
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ipv-label{
    font-weight: bold;
    font-size: 0.8em;
    display: block;
}
.ipv-cell{
    text-align:center;
}