.p-field.p-col {
    margin: 0.5em 0 0.25em 0 !important;
    //padding-left: 0;
    //padding-right: 0;

    .p-float-label {
        width: 100%;

        .p-inputwrapper {
            width: 100%;
        }

        input,
        textarea {
            width: 100%;

            border-radius: 18px;
            outline: none;

            &.p-inputtext {
                padding: 0.5rem 1rem;
            }
        }

        label {
            // 16 Marzo 2023, Si se vuelve a modificar el top, revisar el impacto en el formulario de
            // creación de plantilla
            top: 50%;
            left: 1rem;
        }
        
        .p-inputtextarea{
            + label {
                top: 1rem;
            }
        }

        input:focus~label,
        input.p-filled~label,
        textarea:focus~label,
        textarea.p-filled~label,
        .p-inputwrapper-focus~label,
        .p-inputwrapper-filled~label {
            top: -.5rem;
            left: 0;
        }
        .p-chips-multiple-container{
            row-gap: 0.5rem;
        }

        .p-multiselect {
            @include border-radius($button-border-radius);
        }
    }

    small {
        width: 100%;
        display: block;
        text-align: left;
    }

}

.sd .p-field-checkbox {
    text-align: left;
    margin-bottom: 0;

    &>small {
        margin-bottom: 1rem;
    }
}



.sd .p-password-panel {
    top: 40px !important;
    left: 0 !important;


}

.nota {
    font-size: 0.75rem;
    text-align: left;
}

.p-component.p-dropdown {
    width: 100%;


    border-radius: 18px;
    padding-left: 0.5em;
}

.p-component.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-component.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: $main-color-light-background;
}

.p-chips {
    width: 100%;

    .p-inputtext {

        width: 100%;
    }
}

.p-inputtextarea {
    &.p-invalid {
        border-color: #f44336;
    }
}

.p-component.p-fileupload {
    text-align: center;


    .p-fileupload-buttonbar {
        position: relative;
        padding: 0;
        border: 0;

        .p-button {
            position: absolute;
            bottom: -80px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            border-radius: 18px;
        }
    }

    .p-fileupload-content {
        padding-top: 90px;
        border-style: dashed;
        border-radius: 20px;
        background: rgb(248, 248, 248);
    }
}

.sd .p-inputswitch {
    vertical-align: middle;
    margin-right: 10px;

    &.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $secondary-color-2;



    }

    &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background-color: $secondary-color-1;
    }
}



.input-btn {


    .p-field.p-col {
        // margin-top: 0 !important;
        padding-right: 0;

        .p-inputtext {
            padding-left: 1.1rem;
            border-radius: 2rem;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;
        }

        &+.p-button {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin-top: 16px;
            margin-bottom: 16px;
            padding: 7px 20px !important;
            //max-width: 37%;
        }



    }
}


//Selector de año y mes de calendar
.p-datepicker.p-component {
    max-width: 452px;
    // margin-left: 48px;
    border-radius: 20px;

    .p-monthpicker .p-monthpicker-month {
        border-radius: 30px;
        margin: 2px;
        width: calc(33.3% - 4px);
    }

    table td>span,
    .p-monthpicker .p-monthpicker-month {
        &.p-highlight {
            background: $main-color-light-background;
            border: 1px solid $main-color-1;

        }
    }

    .p-datepicker-header .p-datepicker-title {


        select {
            padding: 3px 6px;
            border-radius: 1rem;
        }
    }

    table td {
        padding: 0.1rem;
    }
}

.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item-group {
    font-weight: 800;
    text-transform: uppercase;
    border-top: 1px solid #dcdcdc;
    padding-top: 1.2em;
    font-size: 0.9em;

    &:first-child {
        border: none;
        padding-top: 0.7em;
    }
}

.file-preview{
    display:flex;
    flex-direction: column;
    align-items: center;
    
    &__title{
        font-weight: 600;
    }
    
    &__content{
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    &__icon-wrapper{
        position: relative;
    }

    &__file-name{
        margin-top: 0.5em;
        font-size: .825em;
        text-align: center;
    }

    .p-button.p-component.delete-button {

        background-color: transparent;
        padding: 0;
        margin: 0;
        border: none;
        color: #ab0906;
        position: absolute;
        right: -1rem;
    }
}

// Ajusta comportamiento raro de los selects en formulario y dashboard
.p-multiselect-label-container, .p-dropdown-label.p-inputtext{
    width: 0;
}

.p-inputgroup {
    .p-inputgroup-addon {
        .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.inputgroup-link {
        .p-float-label {
            width: initial;
        }
    }
}
