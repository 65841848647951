.p-chip {
    margin: 0.2rem;
    border-width: 2px;
    border-style: solid;

    &.SUBMITTED {
        border-color: #DADADA;
    }

    &.PENDING {
        border-color: #FECC48;
    }

    &.APPROVED {
        border-color: #69DC5D;
    }

    &.REJECTED {
        border-color: #F97372;
    }
}