.sd .p-card {
    background-color: #ffffff !important;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 16px;
    @include border-radius(10px);
    @include shadow(3px 3px 10px rgba(0, 0, 0, 0.08));
    position: relative;
    flex-grow: 1;
    padding-top: 0.75rem;

    .p-card-body {
        padding: 0 1rem;

        .splashpage-logo-container {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            width: 100%;
        }
    }
}

$ancho-padding-left: 150px;

.phone-card,
.mail-card {
    &.p-card {
        padding-left: $ancho-padding-left + 20px;

        &:before {
            content: "";
            background: red;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            width: $ancho-padding-left;

            background-size: cover;
            background-position: center;
            background-color: #93adf733;
            border-top-left-radius: 10px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 0px;
        }
    }
}

.p-card {
    &.mail-card {

        &:before {
            background-image: url('../../img/mnv/mail-draw.png');
        }
    }

    &.phone-card {

        //padding-right:$ancho-padding-left + 20px; padding-left: 20px;
        &:before {
            //border-top-left-radius:0px;             border-top-right-radius:10px;             border-bottom-left-radius:0px;             border-bottom-right-radius:10px;            top: 0;            bottom:0;            left: auto;            right: 0;

            background-image: url('../../img/mnv/phone-draw.png');
        }
    }
}

.mail-card,
.phone-card {
    &.p-card {
        padding-right: $ancho-padding-left + 20px;
        padding-left: 20px;

        &:before {
            border-top-left-radius: 0px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 10px;
            top: 0;
            bottom: 0;
            left: auto;
            right: 0;

            //background-image:url('../../img/common/mail-draw.png');

        }
    }
}



.p-col.titulo-interno-card {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding: 1rem 0 0rem 0;
    border-top: 1px solid #ced4da;
    color: $secondary-color-1;

    @include rem(font-size, 22);
    font-weight: 600;

    &.primero {
        padding-top: 0rem;
        border: 0;
    }
}

.single-container {
    .card {
        margin-bottom: 3em;
    }
}

.card-principal {
    text-align: center;
    padding: 1EM;

    @media (min-width: 1024px) {
        padding: 4em;
    }

    p {
        color: #707070;
        margin: 0;
    }

    .texto-card-principal {
        font-weight: 300;
        @include rem(font-size, 30);
    }

    .subtexto-card-principal {
        @include rem(font-size, 16);

        margin: 0 auto 1em auto;

        @media (min-width: 1024px) {
            max-width: 70%;
        }
    }
}

.sd .p-card.card-interna.card-outlined {
    @include shadow(none);
    border: 1px solid $secondary-color-1;
    justify-content: center;
    align-items: center;

    &.center-btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}