.sd {
    .p-datatable {
        display: flex;
        flex-direction: column;


        .p-datatable-wrapper {
            order: 1;
        }

        .p-paginator {
            order: 3;
        }

        .p-datatable-footer {
            order: 2;
        }

        .p-datatable-thead {
            &>tr {
                &>th {


                    background: transparent;

                    font-weight: bold;
                    font-size: 16px;
                    text-align: center;
                    color: #707070;

                    border: 0px;
                    border-radius: 2em;
                    position: relative;
                    white-space: nowrap;


                    &>span {
                        white-space: normal;
                        display: inline-block;
                        max-width: calc(100% - 20px);
                        //overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    input {
                        width: 100%;
                        border-radius: 18px;

                        //background:transparent;
                        &:active,
                        &:focus,
                        &:hover {

                            &::placeholder {
                                /* Chrome, Firefox, Opera, Safari 10.1+ */
                                color: $gray;
                                opacity: 1;
                                /* Firefox */
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10-11 */
                                color: $gray;
                                opacity: 1;
                            }

                            &::-ms-input-placeholder {
                                /* Microsoft Edge */
                                color: $gray;
                                opacity: 1;
                            }
                        }

                        &::placeholder {
                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: $gray;
                            opacity: 1;
                            /* Firefox */
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: $gray;
                            opacity: 1;
                        }

                        &::-ms-input-placeholder {
                            /* Microsoft Edge */
                            color: $gray;
                            opacity: 1;
                        }

                    }

                    .p-multiselect {
                        width: 100%;
                        border-radius: 18px;

                        .p-placeholder {
                            padding-left: 2.357rem;
                        }
                    }
                }

                &:first-child {

                    //border-bottom:20px solid red;
                    &>th span {
                        //margin-bottom:1em;
                    }
                }

                &:nth-child(2) {
                    @include shadow(3px 3px 10px rgba(0, 0, 0, 0.08));
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;

                    &>th {
                        background: white;
                        //background:red;		
                        padding: 5px 6px;
                        border-radius: 0px;

                        &:first-child {
                            border-top-left-radius: 24px;
                        }

                        &:last-child {
                            border-top-right-radius: 24px;
                        }
                    }
                }
            }

        }

        .temp {
            padding-right: 32px;
            padding-left: 5px;

            span.p-sortable-column-icon {
                position: absolute;
                top: 50%;
                right: 6px;
                transform: translateY(-50%);

            }
        }

        &.no-filters {
            .p-datatable-tbody {
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;

                &>tr {
                    &:first-child {
                        border-top-left-radius: 24px;
                        border-top-right-radius: 24px;

                        td:first-child {
                            border-top-left-radius: 24px;
                        }

                        td:last-child {
                            border-top-right-radius: 24px;
                        }
                    }
                }
            }
        }

        .p-datatable-tbody {
            @include shadow(3px 3px 10px rgba(0, 0, 0, 0.08));


            tr {

                &.p-selectable-row.datatable-row-disabled {
                    pointer-events: none;
                    background: #f4f4f4;
                    color: #7c7c7c;
                }

                td {
                    text-align: center;
                    padding: 0.7rem;

                    &:first-child {
                        text-align: start;
                        padding-left: 1.5em;
                    }
                }
            }

            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;



            &>tr {



                &:last-child {
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;

                    td {
                        border-color: transparent;
                        // background-color: red;
                    }

                    td:first-child {

                        border-bottom-left-radius: 24px;
                    }

                    td:last-child {

                        border-bottom-right-radius: 24px;
                    }



                }
            }
        }

        &.with-footer {
            .p-datatable-tbody {
                &>tr {
                    &:last-child {
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;

                        td:first-child {
                            border-bottom-left-radius: 0px;
                        }

                        td:last-child {
                            border-bottom-right-radius: 0px;
                        }
                    }
                }
            }

            .p-datatable-footer {
                background: white;
                @include shadow(3px 3px 10px rgba(0, 0, 0, 0.08));
                text-align: center;


                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
            }
        }
    }

    .p-datatable .p-datatable-tbody .p-datatable-emptymessage {
        td {
            padding: 2em;
        }
    }

    .p-paginator {
        background: transparent;
        //@include border-radius($container-border-radius);
        border-bottom: 0px;
    }

    .p-datatable-loading-overlay {
        background: transparent;

        &+div.p-datatable-wrapper {
            .p-datatable-tbody {
                opacity: 0.2;
                transition: opacity 0.4s;
            }
        }
    }





    .p-datatable-tbody>tr>td .p-column-title {
        display: none;
    }

    .p-datatable {

        &.tabla-s {
            @media screen and (max-width: 600px) {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr>td {
                    text-align: left;
                    display: block;
                    width: 100%;
                    float: left;
                    clear: left;
                    border: 0 none;
                    text-align: left;
                    padding: 0.3rem 1rem;
                }

                .p-datatable-tbody>tr>td .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                .p-datatable-tbody>tr>td:last-child {
                    border-bottom: 2px solid var(--surface-d);
                }
            }

            @media screen and (min-width: 601px) and (max-width: 800px) {
                .p-datatable-thead {
                    tr:nth-child(2) {
                        //display: none !important;
                    }

                    &>tr>th {
                        white-space: normal;
                        padding: 0.3rem;

                        span {

                            width: 100%;
                            overflow: hidden;


                        }
                    }
                }

            }
        }

        &.tabla-m {
            @media screen and (max-width: 800px) {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr>td {
                    text-align: left;
                    display: block;
                    width: 100%;
                    float: left;
                    clear: left;
                    border: 0 none;
                    text-align: left;
                    padding: 0.3rem 1rem;
                }

                .p-datatable-tbody>tr>td .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                .p-datatable-tbody>tr>td:last-child {
                    border-bottom: 2px solid var(--surface-d);
                }
            }

            @media screen and (min-width: 801px) and (max-width: 1000px) {
                .p-datatable-thead {
                    tr:nth-child(2) {
                        //display: none !important;
                    }

                    &>tr>th {
                        white-space: normal;
                        padding: 0.3rem;

                        span {

                            width: 100%;
                            overflow: hidden;


                        }
                    }
                }

            }
        }

        &.tabla-l {
            @media screen and (max-width: 1000px) {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr>td {
                    text-align: left;
                    display: block;
                    width: 100%;
                    float: left;
                    clear: left;
                    border: 0 none;
                    text-align: left;
                    padding: 0.3rem 1rem;
                }

                .p-datatable-tbody>tr>td .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                .p-datatable-tbody>tr>td:last-child {
                    border-bottom: 2px solid var(--surface-d);
                }
            }

            @media screen and (min-width: 1001px) and (max-width: 1200px) {
                .p-datatable-thead {
                    tr:nth-child(2) {
                        //display: none !important;
                    }

                    &>tr>th {
                        white-space: normal;
                        padding: 0.3rem;

                        span {

                            width: 100%;
                            overflow: hidden;


                        }
                    }
                }

            }
        }
    }

    .p-datatable {
        .p-calendar .p-datepicker {

            min-width: 300px;

            td {
                padding: 0;
            }
        }
    }


    .tabla-precios.p-datatable {
        .p-datatable-thead {
            &>tr {
                &:last-child {
                    @include shadow(3px 3px 14px rgba(0, 0, 0, 0.1));
                }
            }
        }


    }
}

.prueba-selecttable {
    tr {
        td:first-child {
            color: $blue;


            &:after {
                content: "";
                width: 1.5rem;
                height: 1.5rem;
                background: url('../../img/cta/eye.svg');
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                opacity: 0;
            }

            &:hover {
                text-decoration: underline;

                &:after {
                    opacity: 1;
                }
            }
        }
    }
}

.cell-status {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    vertical-align: middle;
    margin: 5px;

    span {
        vertical-align: middle;
    }

    &:before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
        vertical-align: middle;
        background: gray;
    }

    &.no-circle {
        &:before {
            content: none;
        }
    }

    i.pi {
        margin-left: .5rem;
        vertical-align: middle;
    }

}

.p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.cell-status-on,
.cell-status-Finalizada {
    color: $green;

    &:before {
        background: $green;
    }
}

.cell-status-off,
.cell-status-Detenida {
    color: $gray;

    &:before {
        background: $gray;
    }
}

.cell-status-alert,
.cell-status-Cancelada,
.cell-status-Fallida {
    color: $red;

    &:before {
        background: $red;
    }
}

.cell-status-Enviando {
    color: $yellow;

    &:before {
        background: $yellow;
    }
}



td {
    .campaign-detail-link-container {
        display: inline-block;
        overflow: hidden;
        // min-width: 10px;
        // // width: 100%; 
        max-width: calc(100% - 75px);
        width: fit-content;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #0b7ad1;
        font-weight: 700;
    }

    a {
        cursor: pointer;
    }
}