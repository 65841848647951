/* Typography */
h1 {
  font-weight: 600;
  margin: 0;
  font-size: 26px;

  &.titulo-pagina-principal {

    color: $secondary-color-1;

  }
}

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;

  &.subtitulo-pagina-principal {
    font-weight: 400;


  }
}

a {
  color: #0b7ad1;
  text-decoration: none;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}



$class-slug: fs !default;
$class-postslug: 5 !default;

@for $i from 1 through 10 {
  .#{$class-slug}-#{$i} {
    font-size: #{$i*16}px;
    font-size: #{$i}em;
  }
}

@for $i from 1 through 10 {
  .#{$class-slug}-#{$i}-#{$class-postslug} {
    font-size: #{$i*16 + 8}px;
    font-size: #{$i+calc($i/2)}em;
  }
}





.italic {
  font-style: italic;
}


.thin {
  font-weight: 100;
}

.extralight {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extrabold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

.white {
  color: white;
}

.tachado {
  text-decoration: line-through;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}