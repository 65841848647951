.logo-intro {
	max-height: 78px;
}

.intro-msg,
.logo-intro {
	margin: 1em 0;

	@media (min-width: 750px) {
		margin: 1em;
	}

	color: white;
}
