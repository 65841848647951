.p-dialog {
    &.p-component{
        background-color: white;
    }
    .p-confirm-dialog-icon {
        display: none;
    }

    .p-confirm-dialog-message {
        margin-left: 0 !important;
    }

    h3 {
        padding: 0;
        margin-bottom: 0.4em;
        margin-top: 0.6em;
    }

    .dato-principal {
        text-align: center;
        padding: 0.5em;
    }

    .p-field-radiobutton {
        margin-bottom: 0.5em;
    }


    &__registered-date {
        text-align: start;
        font-style: italic;
        margin: 0;
    }
    
    // Comenté esto por si se vuelve a encontrar la razón original de haberlo puesto

    // .p-field {
    //     padding-left: 0;
    //     padding-right: 0;
    // }
}

.sd .p-dialog-content {
    overflow: visible;
}

.modal-factura{
    .p-dialog-content {
        padding-bottom: 0 !important;
    }
    .p-inputtextarea{
        max-height: 4.85em;
    }

    .p-dialog-footer{
        display: flex;

        justify-content: flex-end;
     
    }

}

.p-dialog-footer {
    .p-button {
        color: #ffffff;
        background: $secondary-color-1;
        border: 1px solid $secondary-color-1;
        border-radius: 2rem;
        

        &:enabled:hover {
            background: lighten($secondary-color-1, 10);
            color: #ffffff;
            border-color: lighten($secondary-color-1, 10);
        }

        &:enabled:focus {
            box-shadow: 0 0 0 0.2rem #beccd2;
        }

        &:enabled:active {
            background: $secondary-color-2;
            color: #ffffff;
            border-color: $secondary-color-2;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.p-dialog-visible {
    overflow: hidden;

}

.p-dialog {
    width: 100%;
    max-width: 500px;
}



.p-dialog.dialog-m {
    max-width: 800px;
}