.validacion-details {
    .dato-secundario {
        margin: 1em 0;
    }
}

.nombre-archivo {

    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.p-progressbar.p-component {
    border-radius: 14px;
    background: #e9ecef;
    @include rem(font-size, 12);
    border: 2px solid white
}