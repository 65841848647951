.p-progress-spinner {
    position: relative;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    display: inline-block;
}

.p-progress-spinner::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.p-progress-spinner-svg {
    animation: p-progress-spinner-rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.p-progress-spinner-circle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: #d62d20;
    animation: p-progress-spinner-dash-2 1.5s ease-in-out infinite, p-progress-spinner-color-2 6s ease-in-out infinite !important;
    stroke-linecap: round;
}

@keyframes p-progress-spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes p-progress-spinner-dash-2 {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -15px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -104px;
    }
}

@keyframes p-progress-spinner-color-2 {

    100%,
    0% {
        stroke: lighten($secondary-color-1, 20);
    }

    40% {
        stroke: $secondary-color-1;
    }

    66% {
        stroke: $main-color-2;
    }

    80%,
    90% {
        stroke: darken($main-color-2, 20);
    }
}






.center-con {
    display: flex;

    align-items: center;
    //justify-content: center;
    width: 48px;
}



.round {
    position: absolute;
    border: 3px solid $gray;
    width: 30px;
    height: 30px;
    border-radius: 50%;

}

#cta {
    width: 100%;
    cursor: pointer;
}


#cta .arrow {
    left: 5%;
}

.arrow {
    position: absolute;
    bottom: 0;
    margin-left: 0px;
    width: 12px;
    height: 12px;
    background-size: contain;
    top: 6px;
    background-image: url('../../img/common/back-image-blue.svg');

    &.segunda {
        margin-left: 6px;
    }
}


@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    25% {
        opacity: 0;
        transform: translateX(-10px) scale(1);
    }

    36% {
        opacity: 0;
        transform: translateX(10px) scale(1);
    }

    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.arrow.primera.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.round:hover .arrow {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.round:hover .arrow.primera {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}