$colors: (
  green: $green,
  blue: $blue,
  pink: $pink,
  red: $red,
  purple: $purple,
  orange: $orange,
  yellow: $yellow,
  yellow2: $yellow2,
  gray: $gray,
  gray-light: $gray-light,

  mnv-logo-orange: $mnv-logo-orange,
  main-color-1: $main-color-1,
  main-color-2: $main-color-2,
  main-color-3: $main-color-3,
  main-gray: $main-gray,
  secondary-gray: $secondary-gray,
  secondary-color-1: $secondary-color-1,
  secondary-color-2: $secondary-color-2,

);


@mixin colores2() {

  @each $color-name,
  $color in $colors {

    .border-#{"" + $color-name} {
      border-color: $color  !important;
    }

    .color-#{"" + $color-name} {
      color: $color  !important;
    }
  }
}

@include colores2();
