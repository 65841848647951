.sd .profile-photo {
    margin: auto;

    position: relative;

    height: 100px;


    &--circle {
        border-radius: 50%;
        overflow: hidden;

        img{
            height: auto;
        }

    }

    .p-component.p-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        display: block;
        text-transform: uppercase;
        font-size: 0.7em !important;
        line-height: 1.2;
        border-radius: 0;

        background: rgba($secondary-color-1, 0.8);

        .p-button-icon {
            margin: 0;
            margin-bottom: 0.3em;
            display: block;
        }
    }

    &:hover {
        .p-button {
            opacity: 1;
        }
    }
}

.profile-photo-img {

    border-radius: 50%;
    display: block;

}