body,
#root {
    background: lighten(#eef2f9, 2); //$gray-light;


    min-height: 100%; //100vh;
}

.main-container {
    max-width: 1280px;
    margin: 2em auto 4em;
    padding: 0 2em;

    &.fluid {
        margin: 0;
        padding: 0;
        max-width: initial;
    }
}

.single-container {
    //max-width: 1024px;
    margin: auto;
    // padding: 0 2em;
    display: flex;
    //align-items: center;
    min-height: 100vh;

}


.single-container {

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    .logo-intro {
        margin: 10px 12px;
    }
}


.single-background {
    min-height: 100vh;
    //  background-repeat: no-repeat;
    //  background-size: cover;

    min-width: 100%;
    // background-image: url('../../img/common/background-intro.png');
    background: linear-gradient(22deg, #368af7 0%, #2764c2 70%, #1b4f9c 100%);

}





.firebase-emulator-warning {
    &:hover {
        display: none;
    }
}

.back-btn {
    padding: 0.5em;
    margin: 0.2em 0.5em;
    height: 32px;
    cursor: pointer;
}

.header-titulo-pagina,
.header-titulo-secundario-pagina {
    margin-bottom: 1em;

    img {
        margin-right: 0.7em;
    }

    margin-bottom: 1em;

    h2 {
        @include rem(font-size, 16);
        font-weight: 700;
    }
}

.header-titulo-secundario-pagina {

    @media (min-width: 1320px) {
        margin-left: -48px;
    }
}

.icon-back .round {
    cursor: pointer;
}

.p-col.subtitulo-interno {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;
}

.dato-principal {
    padding: 1em 0;
    width: 100%;

    .dato {
        @include rem(font-size, 40);
        line-height: 1;
    }

    .leyenda {
        @include rem(font-size, 14);
        line-height: 1;
    }
}

.dato-secundario {
    padding: 0.5em 0;

    .dato {
        @include rem(font-size, 28);
        line-height: 1;
    }

    .label {
        @include rem(font-size, 14);
        line-height: 1;
    }
}

.dato-terciario {
    margin: 0.5em 0;

    .dato {
        @include rem(font-size, 14);
        line-height: 1;
        font-weight: 800;

        margin-right: 0.6em;
    }

    &.text-align-right .dato {
        margin-right: 0em;
    }

    .label {
        @include rem(font-size, 14);
        line-height: 1;
        font-weight: 400;

    }

    &.reverse {
        .dato {
            margin-right: 0;
            order: 2;
        }

        .label {
            margin-right: 0.6em;
        }
    }
}

.custom-target-icon {
    color: $secondary-color-1;
    font-size: 1rem;
    cursor: pointer;
}


.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 100px;
    width: 100px;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.53);
}

.blurry-text {
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.p-tooltip-text {
    display: inline-block;
}

.tooltip-icon {
    //width: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.testlines {
    border: 0px solid red;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.py-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.my-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.h-100 {
    min-height: 100%;
}

.w-100 {
    width: 100%;
}

.sticky {
    position: sticky !important;
    top: 0;
}