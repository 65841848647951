.apidev-container{
    .dato{
        
        color: transparent;
        //text-shadow: 0 0 10px #000;
        background: rgba(0,0,0,0.1);
        display: inline-block;
        position: relative;
        &:after{
            content: "Pasar cursor por encima para descubrir la llave API   ";
            color:black;
            font-size: 1rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
            line-height: 28px;
            transition: all 0s;
            font-weight: 300;
        }
        &:hover{
            &:after{
                content: "";
            }
            background: transparent;
            color: black;
        }
        transition: all 0.2s;
    }
}