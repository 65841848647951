.sd .validacion-unitaria{
	.p-field.p-col{
		margin: 0 !important;
		padding-right: 0;
		.p-inputtext{
			padding-left: 1.1rem;
			border-radius:2rem;
			border-top-right-radius:0px;
			border-bottom-right-radius:0px;
			border-right:0px;
			height:39px;
		}
		& + .p-button{
			border-top-left-radius:0px;
			border-bottom-left-radius:0px;


			
			//max-width: 37%;
		}

			.p-float-label{
				& > label {
					left:1.1rem;
					white-space: nowrap;
					overflow: hidden;
					width: 100%;
				}
				input:focus ~ label,
			  	input.p-filled ~ label,
			  	textarea:focus ~ label,
			  	textarea.p-filled ~ label,
				  .p-inputwrapper-focus ~ label,
				  .p-inputwrapper-filled ~ label {
				    overflow: visible;
				    
				}
			}


	}
}