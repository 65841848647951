.blacklist-data {
    color: $primary;
}

.blacklist-domain {
    color: darken($main-color-2, 15%);
}

.blacklist-coincidence {
    color: darken($green, 10%);
}

.blacklist-not-allowed {
    color: $red;
}

.sd{
    .card-lista-negra {

     .p-grid > *{
        margin: 0 !important;
    }

    &__footer{
        font-size: 1rem;
        p {
            margin: 0;
            margin-bottom: .5em;
        }
    }
}
    
}