$container-border-radius: 3px; //tables, cards, modals
$button-border-radius: 18px; //buttons

$main-gray: #E5E1D7;
$secondary-gray: #F3F5F9; //#F6F6F6;

$green: #49ad48; //#62D60A;
$blue: #45abec;
$blue-text: #2196F3;
$pink: #FC66B7;
$red: #FF3C3C;
$purple: #BA3FEB;
$orange: #FCAD5A;
$yellow: #FDE450;
$gray: #BAC3C3;
$gray-light: #F4F6FA;

$yellow2: #fdd850;